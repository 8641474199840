<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/label/articlelist')" class="el-icon-close"></i>
      </div>

      <div class="pt20"></div>
    <div class="form"> 
        <div>
            <h4 class="bgl">内容信息</h4>
            <div class="fle">
                <div> 标签名称： <input type="text" disabled v-model="form.name"></div>
            </div>
            <div class="fle">
                <div class="flex" style="line-height: 40px;">
                标签分类：
                 <el-select @change='ftype($event)' v-model="form.f_type.id" placeholder="请选择">
                  <el-option
                    v-for="ite in options"
                    :key="ite.id"
                    :label="ite.name"
                    :value="ite.id">
                  </el-option>
                 </el-select>
                 <el-select v-if="options2.length > 0" v-model="form.c_type.id" placeholder="请选择">
                  <el-option
                    v-for="ite in options2"
                    :key="ite.id"
                    :label="ite.name"
                    :value="ite.id">
                  </el-option>
                 </el-select>
                </div>
            </div>
        </div>
        <div>
            <h4 class="bgl" style="margin: 0;margin-bottom: 6px;">基本信息</h4>
            <div class="fle">
              <div class="flex">
                <div>生成时间：<input type="text" disabled v-model="form.created_at"></div>
                <div>标签编号：<input type="text" disabled v-model="form.id"></div>
                <div>使用量：<input type="text" disabled v-model="form.num"></div>
              </div>
            </div>
        </div>
      <el-form ref="form" :model="form" label-width="180px">
        
  


      <el-form-item>
        <el-button v-if="!look" type="primary" @click="onSubmit">确认</el-button>
      </el-form-item>
    </el-form>
</div>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
          f_type:{
              id:-1
          },
          c_type:{
              id:-1
          }
        },
        options:[],
        options2:[],
        look:false,
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
     onSubmit() {
      let _this = this;
      let postdata = this.form;
      postdata.c_name = postdata.second;
      let obj = {
          id:postdata.id,
          f_type:postdata.f_type.id,
          c_type:postdata.c_type.id,
          updated_at:postdata.updated_at,
      }
      axios.post(config.label,obj)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.goNav('/label/articlelist');
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
      ftype(e){
          let _this = this;
      if(e != -1){
      axios.get(config.selectlabelCategory,{params:{id:e}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options2 = response.data.data;
              _this.form.c_type.id = -1;
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      }else{
        _this.form.c_type.id = -1;
        _this.options2 = [];
      }
      },
      ftype2(e){
          let _this = this;
      axios.get(config.selectlabelCategory,{params:{id:e}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options2 = response.data.data;
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
    init(a){
        let _this = this;
        let obj = JSON.parse(a);
        _this.look = obj.look;
        axios.get(config.labelInfo,{params:{id:obj.id}})
        .then(function (response) {
            if(response.data.code == 200){
                _this.form = response.data.data;
                if(!_this.form.c_type){
                    _this.form.c_type = {id:-1}
                }
                if(!_this.form.f_type){
                    _this.form.f_type = {id:-1}
                }else{
                    _this.ftype2(_this.form.f_type.id);
                }
            }
          })
        .catch(function (error) {
          console.log(error);
        });
        axios.get(config.selectlabelCategory,{params:{id:0}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options = response.data.data
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },// 增加
    add() {
        this.form.second.push({
          id: 0,
          name: ""
        });
    },
    // 删除
    deleteData(index) {
      if (this.form.second.length - 1 < 1) {
        this.$alert("至少输入1项");
        return;
      } else {
        this.form.second.splice(index, 1);
      }
    },
  },
  created(){
     let a = sessionStorage.getItem('edit');
      this.init(a);
  }
}
</script>
<style scoped>
.form{
    text-align: left;
}
.flex{
    display: flex;
}
.el-icon-close:before{
  font-size: 24px;
}
div{
    vertical-align: middle;
    font-size: 16px;
    color: #606266;
    line-height: 25px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}

.fle div{
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    padding: 0 18px 10px 0;
    box-sizing: border-box;
    font-weight: bold;
}
.fle div input{
  height: 20px;
}
.bgl{
    background-color: aliceblue;
    padding: 10px;
    margin-bottom: 5px;
}
</style>
